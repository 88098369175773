/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React /* , {useState} */ from "react"

import "./src/styles/style.scss"

import PageWrapperState from "./src/components/PageWrapperState"

export const wrapPageElement = ({ element, props }) => (
  <PageWrapperState {...props}>{element}</PageWrapperState>
)
