import React /* , {useState} */ from "react"

const PageWrapperState = ({ children }) => {
  /* const [hash, setHash] = useState(window.location.hash)

    const changeHash = () => {
        setHash(window.location.hash);
      }; */
  
  return (<>
    {/* {changeHash()} */}
    <div>{children}</div>
  </>)
}

export default PageWrapperState
